import React from "react";
import { Link } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/outline";

const Btn = (props) => {
	return (
		<Link
			to={props.link}
			className="
      border border-theme rounded-full text-center block p-4 tracking-wider relative
      md:w-96 md:mx-auto
      "
		>
			{props.children}
			<ChevronRightIcon className="inline-block w-5 h-5 text-theme absolute top-1/2 transform -translate-y-1/2 right-4" />
		</Link>
	);
};

export default Btn;
