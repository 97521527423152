import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Contact from "../components/Contact";
import Faq from "../components/Faq";
import Flow from "../components/Flow";
import Btn from "../components/Btn";

const Breadcrumb = () => {
	return (
		<nav>
			<ol
				itemScope
				itemType="https://schema.org/BreadcrumbList"
				className="flex items-center gap-1 w-full text-sm whitespace-nowrap overflow-x-scroll"
			>
				<li
					itemProp="itemListElement"
					itemScope
					itemType="https://schema.org/ListItem"
				>
					<Link
						itemProp="item"
						to="/"
						className="flex items-center gap-1 hover:underline"
					>
						<span itemProp="name">HOME</span>/
					</Link>
					<meta itemProp="position" content="1" />
				</li>
				<li
					itemProp="itemListElement"
					itemScope
					itemType="https://schema.org/ListItem"
				>
					<Link itemProp="item" to="/gallery/">
						<span itemProp="name">ギャラリー</span>
					</Link>
					<meta itemProp="position" content="2" />
				</li>
			</ol>
		</nav>
	);
};

const gallery = ({ data }) => {
	return (
		<Layout>
			<Breadcrumb />
			<div className="mb-4 fast-fadein-animation">
				<h1 className="font-bold text-xl tracking-wide fast-fadein-animation relative my-10">
					{data.site.siteMetadata.title} ギャラリー
				</h1>
			</div>
			<Section title="店内">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/03.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/04.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/03.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/04.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
				</div>
			</Section>
			<Section title="外の景色">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/03.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/04.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/03.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/04.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/01.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
					<figure className="">
						<StaticImage
							className="rounded"
							src="../images/02.jpg"
							placeholder="blurred"
							alt=""
							layout="fullWidth"
						/>
					</figure>
				</div>
			</Section>
			<div className="lg:grid lg:grid-cols-2">
				<Section id="flow" title="ご利用時の流れ">
					<Flow />
				</Section>
				<Section id="faq" title="よくある質問">
					<Faq />
				</Section>
			</div>
			<Section title="お問い合わせ">
				<Contact />
				<Btn link="/">フォームからのお問い合わせはこちら</Btn>
			</Section>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

export default gallery;
